<template>
  <div class="jumbotron p-5 rounded-lg" style="margin-bottom: 2em">
    <h1 class="display-4">Contact Us</h1>
  </div>

  <div class="container container-small">
    <div class="row">
      <div class="col">
        <p><i class="fas fa-location-arrow" aria-hidden="true"></i> Address: 1140 N. Hemlock Rd.</p>
        <p><i class="fas fa-user" aria-hidden="true"></i> Library Director: BillieJo Bluemer</p>
        <p><i class="fas fa-envelope" aria-hidden="true"></i> Email: library@rauchholzlibrary.org</p>
        <p><i class="fas fa-phone-square" aria-hidden="true"></i> Phone: (989) 642-8621</p>
        <p><i class="fas fa-fax" aria-hidden="true"></i> Fax: (989) 771-6483</p>
        <hr>
      </div>
    </div>

      <div class="row">
        <div class="col text-center">
          <h2 style="text-align: center">Hours</h2>
          <table class="table table-hover">
            <tbody>
            <tr>
              <td>Sunday</td>
              <td>Closed</td>
            </tr>
            <tr>
              <td>Monday</td>
              <td>9:00am-5:00pm</td>
            </tr>
            <tr>
              <td>Tuesday</td>
              <td>9:00am-5:00pm</td>
            </tr>
            <tr>
              <td>Wednesday</td>
              <td>1:00pm-8:00pm</td>
            </tr>
            <tr>
              <td>Thursday</td>
              <td>1:00pm-8:00pm</td>
            </tr>
            <tr>
              <td>Friday</td>
              <td>9:00am-5:00pm</td>
            </tr>
            <tr>
              <td>Saturday</td>
              <td>Closed</td>
            </tr>
            </tbody>
          </table>
        </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ContactUs.vue'
}
</script>

<style scoped>
.jumbotron {
  /*background-color: #4e6738;*/
  background-color: #7a9c4e;
  color: #FFFFFF;
  text-align: center;
}

button {
  outline-color: #FFFFFF;
}

a {
  color: #FFFFFF;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .container-small {
    width: 300px;
  }

}
@media (min-width: 992px) {
  .container-small {
    width: 500px;
  }

}
@media (min-width: 1200px) {
  .container-small {
    width: 700px;
  }

}

.container-small, {
  max-width: 100%;
}
</style>
